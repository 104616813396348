/* You can add global styles to this file, and also import other style files */
@import './colors';
@import './bootstrap';
@import './typography';
@import './theme.scss';
@import '../assets/fonts/inter';
@import '../assets/fonts/material-icons';
@import '@vi-succ/design-system-web/styles/dsw.css';

* {
    font-family: 'Inter';
}

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
}

button.mat-mdc-raised-button {
    float: unset !important;

    &:hover {
        background-color: $color-grey-light;
        &.mat-primary {
            background-color: $color-grey;
        }
    }
}

button.mat-mdc-button,
button.mat-mdc-unelevated-button,
button.mat-mdc-raised-button,
a.mat-mdc-unelevated-button,
a.mat-mdc-raised-button,
button.mat-mdc-outlined-button {
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    border-radius: 160px;
    padding: 0 20px;
    letter-spacing: unset;
    height: unset;
}

.success-snackbar {
    background-color: $color-grey-dark;
}

.save-success-snackbar,
.save-error-snackbar {
    width: 100vw;
    margin: 0 !important;
    max-width: 100vw !important;
    display: flex !important;
    justify-content: center;
}

.save-success-snackbar {
    background-color: $color-success-background;
    border-color: $color-success-border;
    color: $color-success-color;
}

.save-error-snackbar {
    background-color: $color-red;
    border-color: $color-red;
    color: $color-white;
}

.error-snackbar {
    background-color: $color-red;
}

// Angular 17 Changes

// Icon

mat-icon {
    overflow: unset !important;
    &.mat-error {
        color: $color-error !important;
    }
}

// Button

.mat-mdc-icon-button .mat-mdc-button-ripple,
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
}

.mat-mdc-icon-button {
    .mat-mdc-button-ripple,
    .mat-mdc-button-persistent-ripple {
        display: none !important;
        &::before {
            display: none !important;
        }
    }
}

// Form Fields

.mat-mdc-form-field {
    &.mat-mdc-form-field {
        &.mat-mdc-form-field {
            &.mat-mdc-form-field {
                &.mat-mdc-form-field {
                    &.mat-mdc-form-field {
                        .mdc-notched-outline__notch {
                            border-left: none !important;
                        }
                    }
                }
            }
        }
    }
}

.mdc-text-field--outlined {
    .mdc-floating-label {
        letter-spacing: 0 !important;
    }
}

// Radio

.mat-mdc-radio-button {
    padding: 0 !important;
    .mdc-radio {
        padding: 0 !important;
    }
}

// Checkbox

.mat-mdc-list-item {
    &.mdc-list-item--with-leading-avatar,
    &.mdc-list-item--with-leading-checkbox,
    &.mdc-list-item--with-leading-icon {
        &.mdc-list-item--with-one-line {
            padding: 0 !important;
            height: auto !important;
        }
    }
}

.mdc-list-item--with-leading-checkbox {
    .mdc-list-item__start {
        margin: 0 0 0 -8px !important;
    }
}

.mdc-list-item__primary-text {
    font-size: 16px !important;
}

.mdc-list {
    margin: 0 !important;
}

.mdc-list-item--with-leading-checkbox {
    .mdc-list-item__start {
        margin: 0;
    }
}

.mat-mdc-list-option {
    .mdc-checkbox {
        padding: 8px !important;
    }
}

.mat-mdc-list-option {
    .mdc-checkbox {
        .mdc-checkbox__background {
            top: 8px !important;
            left: 8px !important;
        }
    }
}

.mdc-list-item {
    &:hover {
        &::before {
            display: none !important;
        }
    }
}

// Select

.mat-mdc-option {
    box-sizing: border-box !important;
}

// Dialog

.mat-mdc-dialog-container {
    .mdc-dialog__surface {
        padding: 24px;
    }
}

.mdc-dialog__title {
    padding: 0 0 8px 0 !important;
    font-weight: 700 !important;
    &::before {
        display: none !important;
    }
}

.mat-mdc-dialog-container {
    .mdc-dialog__content {
        padding: 0 0 20px 0 !important;
        font-family: inherit !important;
        font-size: inherit !important;
        color: inherit !important;
    }
}

.mat-mdc-dialog-actions {
    padding: 0 !important;
    min-height: unset !important;
}

// Paginator

.mat-mdc-paginator {
    .mat-mdc-form-field-infix {
        padding: 4px 0 !important;
        min-height: auto !important;
    }
    .mdc-text-field {
        padding: 0 16px !important;
    }
}

.mdc-text-field--outlined {
    .mdc-notched-outline {
        > * {
            border-color: $color-almost-white !important;
        }
    }
}

// Badge

.mat-badge-large {
    .mat-badge-content {
        display: none !important;
    }
}

// Spinner

.mat-mdc-progress-spinner {
    padding: 0 !important;
}

// Snackbar

.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
        background: transparent !important;
        box-shadow: none !important;
    }
}
