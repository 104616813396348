$color-white: #fff;
$color-almost-white: #d0d0d0;
$color-grey-light: #f5f5f5;
$color-grey-dark: #242424;
$color-grey: #858585;
$color-black: #000;
$color-red: #e05132;
$color-viessmann-red: #ff3e17;
$color-link: #0056b3;
$color-error: #e05132;
$color-primary-dark: #353535;
$color-primary-lighter: #f0f0f0;
$color-primary-light: #f6f6f6;
$color-primary-grey: #999;

$color-green: #75c41b;
$color-success-color: #266733;
$color-success-background: #d4edda;
$color-success-border: #d4edda;

$color-selected: #28a745;
$color-yellow: #f9ab02;

$box-shadow-color: #dedede;

$color-disabled: #666;
$color-unselected: #545454;
$color-action-row: #cfd4d9bd;

$colors: (
    'white': $color-white,
    'grey-light': $color-grey-light,
    'grey-dark': $color-grey-dark,
    'grey': $color-grey,
    'black': $color-black,
    'red': $color-red,
    'green': $color-green,
    'yellow': $color-yellow,
    'viessmann-red': $color-viessmann-red,
    'link': $color-link,
);

@each $name, $code in $colors {
    .color-#{$name} {
        color: $code !important;
    }
    .bg-#{$name} {
        background-color: $code !important;
    }
    .border-#{$name} {
        border-color: $code !important;
    }
    .fill-#{$name} {
        fill: $code !important;
    }
}
