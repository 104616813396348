@import './bootstrap_essentials';
@import './variables.scss';

h1 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 500;
    @include media-breakpoint-down(sm) {
        font-size: 24px;
    }
}

h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    @include media-breakpoint-down(sm) {
        font-size: 20px;
        line-height: 28px;
    }
}

h3 {
    font-size: 20px;
    line-height: 28px;
    @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 26px;
    }
}

h4 {
    font-size: 18px;
    line-height: 24px;
    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }
}

h5 {
    font-size: 18px;
    line-height: 24px;
    @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 20px;
    }
}

html,
body,
input,
textarea,
span,
p,
td,
div {
    font-weight: 400 !important;
}

h1,
h2,
h3,
h4,
h5,
.mat-mdc-dialog-title {
    color: $color-black;
    font-weight: 500 !important;
    font-family: 'Inter' !important;
    margin-bottom: 0;
}

button,
button span,
th,
th span,
a,
a span {
    &:not(.dsw-link) {
        font-weight: 400 !important;
    }
}

input.mat-mdc-input-element {
    color: $color-unselected;
    &[disabled] {
        color: $color-disabled;
    }
}

a {
    &:not(.mat-primary, .link-viessmann-logo, .dsw-link, .dsw-carrier-link) {
        color: $color-black !important;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-decoration: none !important;
        cursor: pointer;
    }
}

p {
    font-size: 16px;
    color: $color-black;
}

.text-small {
    font-size: 13px;
}
